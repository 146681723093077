body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dark * {
    color: white;
}

* {
    /*color: rgba(30, 41, 59, 1 );*/
    scroll-behavior: smooth;
}

.google-map-container > div {
    position: static !important;
}

*::-webkit-scrollbar {
    width: 9px; /* width of the entire scrollbar */
    height: 9px; /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
    background: rgba(200, 200, 200, 1); /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
    background-color: rgb(38 38 38); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid rgba(200, 200, 200, 1); /* creates padding around scroll thumb */
}

.dark *::-webkit-scrollbar-track {
    background: rgba(150, 150, 150, 1); /* color of the tracking area */
}

.dark *::-webkit-scrollbar-thumb {
    background-color: rgba(30, 41, 59, 1); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid rgba(150, 150, 150, 1); /* creates padding around scroll thumb */
}

@tailwind base;
@tailwind components;
@tailwind utilities;