.react-select-container {
  z-index: 1000;
}
.react-select-container .react-select__control {
  border: 1px solid rgb(209 213 219);
  background-color: transparent;
  min-height: 2.5rem;
}
.is-invalid .react-select__control {
  border: 1px solid rgb(239 68 68);
  background-color: transparent;
  min-height: 2.5rem;
}
.react-select-container .react-select__control .react-select__value-container {
  padding: 4px 16px;
  height: 100%;
}
.react-select-container .react-select__control .react-select__value-container .react-select__input-container{
  margin: 0;
  padding: 0;
}
.react-select-container .react-select__control .react-select__value-container .react-select__input-container .react-select__input {
  color: black !important;
}
.dark .react-select-container .react-select__control .react-select__value-container .react-select__input-container .react-select__input {
  color: white !important;
}
.react-select-container .react-select__control .react-select__value-container .react-select__placeholder {
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  color: black;
}
.dark .react-select-container .react-select__control .react-select__value-container .react-select__placeholder {
  color: white;
}
.dark .react-select-container .react-select__control .react-select__value-container .react-select__single-value {
  color: white;
  margin: 0;
}
.react-select-container .react-select__control .react-select__value-container .react-select__multi-value {
  background-color: #EF4444 !important;
}
.react-select-container .react-select__control .react-select__value-container .react-select__multi-value .react-select__multi-value__label{
  color: white !important;
}
.react-select-container .react-select__control .react-select__value-container .react-select__multi-value__remove {
  color: white !important;
}
.react-select-container .react-select__control .react-select__indicators {

}
.react-select__menu-portal .react-select__menu {
  z-index: 1000;
}
.dark .react-select__menu-portal .react-select__menu {
  z-index: 1000;
  background-color: rgba(30, 41, 59, 1);
}
.react-select__menu-portal .react-select__menu .react-select__menu-list {
  z-index: 1000;
  background-color: rgb(209 213 219) !important;

}
.dark .react-select__menu-portal .react-select__menu .react-select__menu-list {
  z-index: 1000;
  background-color: rgb(38 38 38) !important;

}
.react-select__menu-portal .react-select__menu .react-select__menu-list .react-select__option {
  padding: 8px 16px;
}
.react-select__menu-portal .react-select__menu .react-select__menu-list .react-select__option--is-selected {
  background-color: #EF4444 !important;
  color: white !important;
}
.react-select__menu-portal .react-select__menu .react-select__menu-list .react-select__option--is-focused {
  background-color: rgb(229 231 235) !important;
  color: black !important;
}
.dark .react-select__menu-portal .react-select__menu .react-select__menu-list .react-select__option--is-focused {
  background-color: rgb(64 64 64) !important;
  color: white !important;
}