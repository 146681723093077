code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dark * :not(span){
    color: white;
}
.rdw-editor-toolbar span {
    color: black;
}
.rdw-editor-toolbar * {
    color: black;
}
.rdw-embedded-modal, .rdw-image-modal, .rdw-emoji-modal, .rdw-link-modal, .rdw-colorpicker-modal {
    box-shadow: none !important;
}

.rdw-editor-toolbar ul li {
    color: black;
}

.rdw-editor-toolbar button {
    color: black;
}
.rdw-editor-toolbar input {
    color: black;
    background-color: transparent;
}